import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';

interface TaskStatus {
  color: string;
  text: string;
  icon: typeof ClockCircleOutlined;
}

const TaskStatusMap: Record<string, TaskStatus> = {
  PENDING: {
    color: '#1890ff',
    text: 'Pending',
    icon: ClockCircleOutlined,
  },
  PROCESSING: {
    color: '#1890ff',
    text: 'Processing',
    icon: SyncOutlined,
  },
  COMPLETED: {
    color: '#52c41a',
    text: 'Completed',
    icon: CheckCircleOutlined,
  },
  CANCELLED: {
    color: '#ff4d4f',
    text: 'Cancelled',
    icon: StopOutlined,
  },
  FAILED: {
    color: '#ff4d4f',
    text: 'Failed',
    icon: CloseCircleOutlined,
  },
  INCOMPLETE: {
    color: '#ff4d4f',
    text: 'Incomplete',
    icon: CloseCircleOutlined,
  },
};

export default TaskStatusMap;

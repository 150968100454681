import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import TaskTable from './components/TaskTable';
import { TaskService } from './services';
import './styles/TaskTable.scss';
import './styles/TaskPreviewModal.scss';

const TaskModule: React.FC = () => {
  return (
    <div className="task-module">
      <Typography.Title level={3}>Task Management</Typography.Title>
      <TaskTable />
    </div>
  );
};

export default TaskModule;

// Get the difference in days between two dates
/**
 *
 * @param date1 The first date object
 * @param date2 The second date object
 * @returns The difference in days between the two dates
 */
export const differenceInDays = (date1: Date, date2: Date) => {
  const date1InMs = date1.getTime();
  const date2InMs = date2.getTime();
  const differenceInMs = date1InMs - date2InMs;
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
  return Math.floor(differenceInDays);
};

/**
 * Returns a string representation of the date in the format "DD Month YYYY"
 * @param date The date object
 * @returns The formatted date string
 */
export const getDateString = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export const getMMDDYYYYString = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  });
};

export const getDayNameAndDate = (dateString: string) => {
  if (!dateString) {
    return 'N/A';
  }

  // Convert date string to UTC by appending 'Z' (ISO 8601 format)
  const date = new Date(dateString + 'T00:00:00Z');

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Use UTC methods to avoid local time zone issues
  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${dayOfWeek}, ${month}/${day}`;
};

/**
 * Returns a string representation of the date and time in the format "DD Month YYYY, HH:MM"
 * @param date The date object
 * @returns The formatted date and time string
 */
export const getDateTimeString = (dateString: string) => {
  return new Date(dateString).toLocaleString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const getShortDateString = (dateString: string) => {
  return new Date(dateString).toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
  });
};

export const getNumericDateString = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
  });
};

export const getDateAndTimeString = (isoString: string) => {
  const date = new Date(isoString);

  // Format the date as 'DD/MM/YYYY'
  const formattedDate = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  // Format the time as 'hh:mm AM/PM'
  const formattedTime = date
    .toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase();

  return { date: formattedDate, time: formattedTime };
};

export const getFloridaTime = (dateString: string) => {
  const date = new Date(dateString);

  return date.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short', // This will add 'ET' to the output
  });
};
